import { Helmet } from 'react-helmet';
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ComingSoonPage, PrivacyPolicyPage } from './pages';
import './App.css';

function App() {
  return (
    <div className="App">
      <Helmet>
				<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" content="Ambimi" />

				<title>Home | Ambimi</title>
				<link rel="canonical" href="https://www.ambimi.com/" />
				<meta property="og:title" content="Home | Ambimi" />
				<meta property="og:url" content="https://www.ambimi.com" />
				<meta property="og:site_name" content="Ambimi" />
				<meta property="og:type" content="website" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content="Home | Ambimi" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;1,400&display=swap" rel="stylesheet" />
      </Helmet>
			<Router>
					<Switch>
						<Route exact path="/">
              <Header className="header home-header" />
							<ComingSoonPage />
              <Footer />
						</Route>
						<Route path="/privacy-policy">
              <Header className="header privacy-header" />
							<PrivacyPolicyPage />
              <Footer />
						</Route>
					</Switch>
			</Router>
    </div>
  );
}

export default App;
