export const PrivacyPolicy = () => {
  return (
    <div className="privacy">
      <h1>Privacy Policy</h1>
        <p>
        Ambimi Technology Services Inc. (“Ambimi”) and its Affiliates places the utmost value upon the privacy of our customers, and all related individuals.  We will endeavour that all personal information is protected throughout each area of our business.  Personal information is collected, processed and disclosed by Ambimi for the purpose of executing all functions and services of the application and we adhere to strict guidelines as specified by the Personal Information Protection & Electronic Documents Act (PIPEDA) in all cases to ensure that you are protected.
        </p>
        <p>
        Ambimi acts in compliance with Canada’s Anti-Spam Legislation. At any time you wish to stop receiving electronic communication from Ambimi, please reply to our correspondence or contact us directly and request to be removed from our mailing list.
        </p>

        <p className="strong">What Information Does Ambimi Collect?</p>
        <p>
          Ambimi requests that our customers provide us with particular pieces of information that are necessary to execute all functions and services of the application.  The information collected includes but may not be limited to the following:
          <ul>

             <li>Personal Contact Information</li>

             <li>Employer Contact Information</li>

             <li>Work Eligibility Information (Work Visa, Permit, etc.)</li>

             <li>Records or Transcripts from Educational Institutions or accredited Associations</li>

             <li>Skills Testing Results/Potential Employer Administered Test Results</li>

             <li>Payroll Information/Tax Deduction Information and/or Banking Details for the 
               purpose of processing payments</li>

          </ul>
        </p>
        <p className="strong">
        Why is this Information Collected?
        </p>
        <p>
        Ambimi requests information from our customers and related individuals in order to execute all functions and services of the application.  We will never collect more information than is deemed necessary execute all functions and services of the application. Any requirement of additional information will be disseminated by Ambimi only after receiving specific consent from the customer.
        </p>
        <p className="strong">
        When is the Information Shared by Ambimi?
        </p>
        <p>
        The personal information you provide will never be sold, traded, or leased to any external customers by Ambimi.  Ambimi endeavours to exercise care and consideration when sharing your information, and accordingly we will limit disclosure to ‘as required’ basis.
        </p>
        <p className="strong">
        Where is the Information Stored and How Long is it Retained?
        </p>
        <p>
        Ambimi stores information through a secure electronic server environment with strict accordance to secure procedures of electronic filing. Ambimi may store information on secure servers located in various locations within North America, including locations outside of Canada.
        </p>

        <p className="strong">
        By registering with Ambimi Technology Services Inc., you acknowledge you have read and agree to our Privacy Policy.
        </p>
        <p>
        For the purposes of this Privacy Policy, Affiliate shall mean, with respect to any person, partnership, join venture, corporation or other form of enterprise directly or indirectly controls, is controlled by, or is under common control with Ambimi (for the purposes of this Privacy Policy, “controls” means possession, directly or indirectly, of the power to direct or cause direction of management and policies through ownership of voting securities, contract, voting trust or otherwise).
        </p>
    </div>
  );
}
