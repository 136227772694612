import { Link } from 'react-router-dom';

export const Header = (props) => {
  return (
    <div>
      <nav className={props.className}>
        <ul>
          <li><Link to="/" className="home">Home</Link></li>
          <li><Link to="/privacy-policy" className="privacy">Privacy Policy</Link></li>
        </ul>
      </nav>
    </div>
  );
};
